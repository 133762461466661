<template>
  <v-container fluid tag="section">
    <v-row>
      <v-col cols="12" md="6">
        <base-material-card
          color="success"
          inline
          comment-icon="mdi-email"
          title="Modify email content"
          class="px-5 py-3"
        >
          <v-form class="mt-5">
            <v-text-field
              v-model="item.title"
              :label="$t('subject')"
              outlined
            />

            <v-text-field
              v-model="item.writer"
              :label="$t('writer')"
              outlined
            />

            <v-textarea
              v-model="item.content"
              :label="$t('content')"
              outlined
            />

            <v-checkbox label="Test send" class="mt-0" />
          </v-form>

          <v-card-actions class="pl-0">
            <v-btn color="success" min-width="100" @click="submitUpdate"
              >{{ this.$t("edit") }}
            </v-btn>
          </v-card-actions>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    urlIndex: "/contents/contents-email",

    item: {
      title: "",
      writer: "",
      content: "",
    },

    snackbar: false,
    notificationMessage: "",
  }),
  computed: {
    urlShow() {
      return `/contents/contents-email/${this.$route.params.id}`;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        console.log("ROUTE edit", this.$route.params.id);
        const res = await this.authGet(
          `/api/contents/contents-email/${this.$route.params.id}`
        );
        console.log(res.data.contentsEmail);
        this.item = res.data.contentsEmail;
      } catch (e) {
        const { response } = e;
        if (response) {
          this.showNotification(response.data);
        } else {
          this.showNotification(e);
        }
      }
    },

    async submitUpdate() {
      try {
        const formData = {
          title: this.item.title,
          writer: this.item.writer,
          content: this.item.content,
        };
        console.log("before update,", typeof formData, formData);
        const res = await this.authPut(
          `/api/contents/contents-email/${this.$route.params.id}`,
          formData
        );
        console.log("after update,", res);
        if (res.data.result) {
          // this.showNotification("Updated");
          location.href = this.urlShow;
        }
      } catch (e) {
        console.error(e);
      }
    },

    showNotification(msg) {
      this.notificationMessage = msg;
      this.snackbar = true;
    },
  },
};
</script>
